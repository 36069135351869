import { type ParsedUrlQuery } from 'querystring';

import { type CampaignConfig, attributeCampaigns } from '@vakantiesnl/services/src/route-params';
import { type MappedDestinationView } from '@vakantiesnl/types/src/geo';

import { isNotEmpty } from './arrayUtils';
import { getValidDateRangeFromParams } from './dateRangeUtils';
import { isBetweenMinMaxDepartureDate } from './dateUtils';
import { type GeoQueryParams, type GeoState } from '../hooks/queries/geo/interfaces';

export function getValidCountriesFromQuery(
	geoParams: GeoQueryParams,
	countries: GeoState['countries'],
): MappedDestinationView[] {
	if (geoParams.type === 'slug') {
		const selectedCountry = countries.find((c) => c.slug === geoParams.country);

		return selectedCountry ? [selectedCountry] : [];
	}

	const selectedCountries = geoParams.countries
		.map((countryId) => {
			const country = countries.find((country) => country.entityId == countryId);
			return country;
		})
		.filter(isNotEmpty);

	return selectedCountries;
}

export function getValidRegionsFromQuery(
	geoParams: GeoQueryParams,
	regions: GeoState['regions'],
): MappedDestinationView[] {
	if (geoParams.type === 'slug') {
		const selectedRegion = regions[geoParams.country]?.find((r) => r.slug === geoParams.region);

		return selectedRegion ? [selectedRegion] : [];
	}

	const selectedRegions: MappedDestinationView[] = [];

	for (const country in regions) {
		for (const region of regions[country]) {
			if (geoParams.regions.includes(region.entityId)) {
				selectedRegions.push(region);
			}
		}
	}

	return selectedRegions;
}

export function getValidCitiesFromQuery(
	geoParams: GeoQueryParams,
	cities: GeoState['cities'],
): MappedDestinationView[] {
	if (geoParams.type === 'slug') {
		const selectedCity = cities[geoParams.region]?.find((c) => c.slug === geoParams.city);

		return selectedCity ? [selectedCity] : [];
	}

	const selectedCities: MappedDestinationView[] = [];

	for (const region in cities) {
		for (const city of cities[region]) {
			if (geoParams.cities.includes(city.entityId)) {
				selectedCities.push(city);
			}
		}
	}

	return selectedCities;
}

const routeParamKeys = [
	'country',
	'countries',
	'region',
	'regions',
	'city',
	'cities',
	'accoType',
	'accommodation',
	'themes',
	'campaign',
	'airports',
	'sort',
	'page',
	'pax',
	'mealplans',
	'awards',
	'distances',
	'transport',
	'rating',
	'facilities',
	'durations',
	'o_durations',
	'departure_date',
	'pre_offset',
	'post_offset',
	'min_price',
	'max_price',
	'flexible_departure_date',
	'slug',
	'deals',
	'referURL',
	'chains',
	'chain',
	'stars',
	'tourOperators',
	'tourOperator',
	'airlines',
	'flight_provider',
	'attributes',
	'entityId',
];

export const getAllParamsExceptRouteParams = (routerQuery: ParsedUrlQuery): URLSearchParams => {
	const unknownParamsToKeep = new URLSearchParams();

	Object.entries(routerQuery).forEach(([routerParamKey, routerParamValue]) => {
		const isRouterParam = routeParamKeys.includes(routerParamKey);

		if (!isRouterParam) {
			const parsedValue = parseToValidParamValue(routerParamValue);
			unknownParamsToKeep.append(routerParamKey, parsedValue);
		}
	});

	return unknownParamsToKeep;
};

export function parseToValidParamValue(routerParamValue?: string | string[] | number[]): string {
	if (!routerParamValue) {
		return '';
	}

	if (Array.isArray(routerParamValue)) {
		return routerParamValue?.length ? routerParamValue.join('+') : '';
	}

	return routerParamValue;
}

export const parseQueryParam = (
	queryParam: string | string[] | undefined,
	dataToMatch?: Record<string, string> | string[],
): string[] | undefined => {
	if (!queryParam || !queryParam.length) {
		return undefined;
	}

	const splittedQueryParam = Array.isArray(queryParam) ? queryParam : queryParam.split(/[\+\/]/);

	if (!dataToMatch) {
		return splittedQueryParam;
	}

	return splittedQueryParam
		.map((value) => {
			let find: undefined | boolean | string[];
			if (Array.isArray(dataToMatch)) {
				find = dataToMatch.includes(value) ? [value] : false;
			} else {
				// For e.g. domains ['city_trip', 'stedentrip']
				find = Object.entries(dataToMatch).find((item) => item[1] === value);
			}
			if (find) {
				return find[0];
			}
			return;
		})
		.filter(isNotEmpty);
};

export function parseQueryDateParam(queryParam: string | string[] | undefined): string[] | undefined {
	if (typeof queryParam !== 'string') {
		return undefined;
	}

	const splittedDateParams = queryParam.split(/[\+\/]/);

	if (splittedDateParams.length > 2) {
		return undefined;
	}

	if (splittedDateParams.length === 1) {
		const date = splittedDateParams[0];

		return isBetweenMinMaxDepartureDate(new Date(date)) ? [date] : undefined;
	}

	const [minDate, maxDate] = splittedDateParams;

	return getValidDateRangeFromParams(minDate, maxDate);
}

const onlyNumbers = /^\d+$/;
export function getNumberIfValid(value: string | string[] | undefined): string | undefined {
	if (typeof value === 'string' && onlyNumbers.test(value)) {
		return value;
	}

	return undefined;
}

export function getCampaignFromQuery(query: ParsedUrlQuery): CampaignConfig | undefined {
	const campaign = query.campaign;

	const campaignConfig = attributeCampaigns;

	if (!campaignConfig || typeof campaign !== 'string') {
		return undefined;
	}

	return campaignConfig[campaign as keyof typeof attributeCampaigns] ?? undefined;
}

/**
 * Check if any geo is selected based on the query, order of priority:
 * 1. single geo in query
 * 2. multiple geo in query
 * 3. geo as part of campaign from query
 **/
export function getGeoParamsFromQuery(query: ParsedUrlQuery): GeoQueryParams {
	if (typeof query.country === 'string' || typeof query.region === 'string' || typeof query.city === 'string') {
		return {
			type: 'slug',
			country: typeof query.country === 'string' ? query.country : '',
			region: typeof query.region === 'string' ? query.region : '',
			city: typeof query.city === 'string' ? query.city : '',
		};
	}

	if (typeof query.countries === 'string' || typeof query.regions === 'string' || typeof query.cities === 'string') {
		const countries = typeof query.countries === 'string' ? query.countries.split('+') : [];
		const regions = typeof query.regions === 'string' ? query.regions.split('+') : [];
		const cities = typeof query.cities === 'string' ? query.cities.split('+') : [];

		return {
			type: 'id',
			countries,
			regions,
			cities,
		};
	}

	const campaign = getCampaignFromQuery(query);

	return {
		type: 'id',
		countries: campaign?.countries ?? [],
		regions: campaign?.regions ?? [],
		cities: campaign?.cities ?? [],
	};
}

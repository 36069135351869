import { type MicroCopy } from '@vakantiesnl/types';
import dayjs, { type Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

const EARLIEST_DEPARTURE_IN_DAYS = 2;

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export const getDayName = (date: Date): string => date.toLocaleString('nl-NL', { weekday: 'short' });
export const getMonthName = (date: Date): string => date.toLocaleString('nl-NL', { month: 'long' });
export const getMonthNameShort = (date: Date): string => `${date.toLocaleString('nl-NL', { month: 'short' })}.`;
export const getYear = (date: Date): string => dayjs(date).format('YYYY');

export const getDayMicroCopy = (dayCount: number, isMobile: boolean, microCopies: MicroCopy): string => {
	return dayCount === 1
		? microCopies['common.daysSingular']
		: isMobile
			? microCopies['common.daysShort']
			: microCopies['common.days'];
};

export const getNightMicroCopy = (nightCount: number, microCopies: MicroCopy): string => {
	return nightCount === 1 ? microCopies['common.nightsSingular'] : microCopies['common.nights'];
};

export const getMonthDifference = (d1: Date, d2: Date): number => {
	const date1 = dayjs(d1);
	const date2 = dayjs(d2);

	return date1.diff(date2, 'month');
};

export const getTimeFromDate = (date: Date): string => {
	return dayjs(date).format('HH:mm');
};

export const convertMinsToHrsMins = (mins: number): string => {
	const h = Math.floor(mins / 60);
	const m = mins % 60;
	return `${h}:${m < 10 ? '0' + m : m}`;
};

export const getDateDMY = (date: Date | string): string => dayjs(date).format('DD-MM-YYYY');

export const getDateYMD = (date: Date | dayjs.Dayjs): string => dayjs(date).format('YYYY-MM-DD');

export const getDateISO = (date: Date): string => dayjs(date).format();

export const getDateStringWithMonth = (date: Date): string =>
	`${date.getDate()} ${getMonthName(date)} ${date.getFullYear()}`;

export const getDateStringWithoutYear = (date: Date): string => `${date.getDate()} ${getMonthName(date)}`;

export const getTimeStringBetweenDates = (fromDate: Date, toDate: Date): string => {
	const difference = toDate.getTime() - fromDate.getTime();
	const resultInMinutes = Math.round(difference / 60000);
	return convertMinsToHrsMins(resultInMinutes);
};

export const compareBetweenTimes = (checkDate: Dayjs, fromDate: Dayjs, toDate: Dayjs): boolean => {
	const checkTime = dayjs().set('h', checkDate.get('h')).set('m', checkDate.get('m')).set('s', 0);
	const fromTime = dayjs().set('h', fromDate.get('h')).set('m', fromDate.get('m')).set('s', 0);
	const toTime = dayjs().set('h', toDate.get('h')).set('m', toDate.get('m')).set('s', 0);

	return checkTime.isSameOrAfter(fromTime) && checkTime.isSameOrBefore(toTime);
};

type DepartureOffset = { pre: number; post: number };

export const getDepartureDateOffset = (
	selectedDate: Date,
	flexibleDepartureDate?: boolean | number,
): DepartureOffset => {
	const earliestDeparture = getMinDepartureDate().startOf('day');
	const differenceInDays = dayjs(selectedDate).startOf('day').diff(earliestDeparture, 'day');

	let preOffset;
	let postOffset = 2;

	if (typeof flexibleDepartureDate === 'number') {
		preOffset = differenceInDays >= flexibleDepartureDate ? flexibleDepartureDate : differenceInDays;
		postOffset = flexibleDepartureDate;
	} else {
		preOffset = differenceInDays >= EARLIEST_DEPARTURE_IN_DAYS ? EARLIEST_DEPARTURE_IN_DAYS : differenceInDays;
	}
	preOffset = preOffset < 0 ? 0 : preOffset;

	return {
		post: postOffset,
		pre: preOffset,
	};
};

/* Min departure date is current day + EARLIEST_DEPARTURE_IN_DAYS days */
export const getMinDepartureDate = (): dayjs.Dayjs => dayjs().startOf('day').add(EARLIEST_DEPARTURE_IN_DAYS, 'day');

/** Max departure date is current day + 18months with the last day of the month */
export const getMaxDepartureDate = (): dayjs.Dayjs => {
	/* Date in 18 months */
	const dateObj = dayjs().startOf('day').add(18, 'month');

	return dateObj.date(dateObj.daysInMonth());
};

/** Checks if the passed date is between the minimum and maximum departure date */
export const isBetweenMinMaxDepartureDate = (date: Date | dayjs.Dayjs): boolean => {
	const minDate = getMinDepartureDate();
	const maxDate = getMaxDepartureDate();
	const dateToCheck = dayjs(date);

	if (dateToCheck.isSameOrAfter(minDate) && dateToCheck.isSameOrBefore(maxDate)) return true;
	return false;
};

export const getFormatedFullMonthDate = (date: Date, format = 'DD-MM-YYYY'): string => dayjs(date).format(format);

export const departureDateFilterValueOrDefault = (
	departureDateFilterValue: string | null,
	defaultDepartureDate: Date,
): string => {
	if (!departureDateFilterValue) {
		return getDateYMD(defaultDepartureDate);
	}

	const filterValueDate = dayjs(departureDateFilterValue, 'YYYY-MM-DD');

	if (filterValueDate.isAfter(dayjs(defaultDepartureDate), 'day')) {
		return getDateYMD(filterValueDate.toDate());
	}

	return getDateYMD(defaultDepartureDate);
};

export const getValidDateYMDOrNull = (date: string): string | null => {
	const dateFormat = 'YYYY-MM-DD';
	const queryParamDepartureDate = dayjs(date, dateFormat, true);

	return queryParamDepartureDate.isValid() ? queryParamDepartureDate.format(dateFormat) : null;
};

export const isValidDate = (day: number, month: number, year: number, minYear: number): boolean => {
	if (year < minYear) return false;
	const date = new Date(year, month - 1, day);
	return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
};

export function parseToYMDDate(date: string | Dayjs): Dayjs {
	return dayjs(date, 'YYYY-MM-DD').startOf('day');
}

export function getFormattedDateIfValid(value: string): string | undefined {
	const date = dayjs(value).startOf('d');

	/** don't pass the date if it's not in between our min and max date values */
	if (date.isValid() && isBetweenMinMaxDepartureDate(date)) {
		return getDateYMD(date);
	}
}

export function getDateWithoutTime(date: string): dayjs.Dayjs {
	// Start of day to avoid time differences
	return dayjs(date).startOf('day');
}

import { type GetServerSidePropsContext, type NextPageContext } from 'next';

import Cookies from 'js-cookie';
import nextCookie from 'next-cookies';

export const getClientIdFromGACookie = (ctx?: NextPageContext | GetServerSidePropsContext): string | undefined => {
	let cookie;
	if (ctx) {
		const { _ga } = nextCookie(ctx);

		cookie = _ga;
	} else {
		cookie = Cookies.get('_ga');
	}
	return cookie?.replace(/([GA].\d.\d.)/, '');
};
